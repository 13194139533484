 <template>
    <modal
        height="auto"
        :show="true"
        scrollable
        size="sm"
        @hide="$emit('hide')"
    >

        <div slot="header">
            <h2 class="font-normal">Carrier</h2>
        </div>

        <div slot="body">
            <div v-if="loading" class="flex flex__justify-center w__100-p">
                <spinner />
            </div>

            <form v-else @submit.prevent="" style="height: 500px;">
                <modal-field label="Carrier" class="mb-3">
                    <multiselect
                        v-model="carrier"
                        :options="carriersList"
                        class="no-branding"
                        label="name"
                        track-by="id"
                        @input="updateServiceList"
                    >
                        <template slot="clear">
                            <div
                                v-if="carrier"
                                @click="carrier = null; service = null; serviceList = []"
                                class="w-4 absolute top-0 bottom-0 right-0 z-10 flex items-center mr-10 cursor-pointer"
                            >
                            <svg-importer icon-name="icons/close" key="close-1" />
                            </div>
                        </template>
                    </multiselect>
                </modal-field>

                <modal-field label="Service" class="mb-3">
                    <multiselect
                        v-model="service"
                        :options="serviceList"
                        label="name"
                        track-by="id"
                        class="no-branding"
                        @search-change="searchService"
                        @open="searchService"
                    >
                        <template slot="clear">
                            <div
                                v-if="service"
                                @click="service = null"
                                class="w-4 absolute top-0 bottom-0 right-0 z-10 flex items-center mr-10 cursor-pointer"
                            >
                                <svg-importer icon-name="icons/close" key="close-2" />
                            </div>
                        </template>
                    </multiselect>
                </modal-field>

                <modal-field label="Mode" class="mb-3">
                    <multiselect
                        v-model="transport_mode"
                        label="label"
                        track-by="value"
                        :options="dictionary.transport_modes"
                        class="no-branding"
                        clearable
                    >
                        <template slot="clear">
                            <div
                                v-if="transport_mode"
                                @click="transport_mode = null"
                                class="w-4 absolute top-0 bottom-0 right-0 z-10 flex items-center mr-10 cursor-pointer"
                            >
                                <svg-importer icon-name="icons/close" key="close-3" />
                            </div>
                        </template>
                    </multiselect>
                </modal-field>
            </form>
        </div>
        
        <div slot="footer" class="w__100-p">
            <div class="flex justify-end w-full">
                <div class="flex">
                    <button class="px-10 btn-primary" :disabled="!disabled" @click="save">Save</button>
                    
                    <button class="px-8 ml-4 btn-grey-outline" @click="$emit('hide')">Cancel</button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import axios from 'axios';
import Spinner from '~/components/Spinner.vue';

export default {
    name: 'CarrierModal',

    components: {
        Spinner
    },

    props: {
        dictionary: {
            type: [Object, Array],
        },
        value: {
            type: Object,
            default: () => { return {} },
        }
    },

    data() {
        return {
            transport_mode: null,
            carriersList: [],
            serviceList: [],
            carrier: null,
            service: null,
            loading: true,
        }
    },

    computed: {
        disabled() {
            return this.service && this.transport_mode;
        }
    },

    methods: {
        async save() {
            if (!this.disabled) {
                return;
            }

            const result = {
                transport_mode: this.transport_mode ? this.transport_mode.value : null,
                carrier_service_id: this.service ? this.service.id : null,
            }

            await axios.patch(`${this.$apiUrl.consignments.base}/${this.value.uuid}/carrier`, result);

            this.$emit('hide');
            this.$emit('refreshTable');
        },

        async searchService(value) {
            if (this.carrier) {
                return;
            }

            const query = value && value.trim().length ? `name=${value}` : '';

            const { data: { data } } = await axios.get(this.$apiUrl.carrierServices.search + `?limit=10&${query}`);

            this.serviceList = data;
        },

        async initCarriersList() {
            const { data: { data } } = await axios.get(this.$apiUrl.carriers.base + '?paginate=0');

            this.carriersList = data;
        },

        async initServicesList() {
            this.service = null;

            if (!this.value.carrier_id) {
                return [];
            }

            const { data: { data } } = await axios.get(`${this.$apiUrl.carriers.base}/${this.value.carrier_id}/services/search`);

            this.serviceList = data;
        },

        async updateServiceList() {
            this.service = null;

            if (!this.carrier) {
                return [];
            }

            const { data: { data } } = await axios.get(`${this.$apiUrl.carriers.base}/${this.carrier.id}/services/search`);

            this.serviceList = data;
        }
    },

    async created() {
        this.loading = true;

        await this.initCarriersList()
        await this.initServicesList();

        this.transport_mode = this.dictionary.transport_modes.find(item => {
            return item.label === this.value.transport_mode || item.value === this.value.transport_mode;
        })

        this.carrier = this.carriersList.find(item => {
            return item.name === this.value.carrier_name || item.id === this.value.carrier_id;
        });

        this.service = this.serviceList.find(item => {
            return item.id === this.value.carrier_service_id || item.name === this.value.carrier_service_name;
        })

        this.loading = false;
    }
}
</script>
